/* Style the submit button with a specific background color etc */
#title {
    text-align: center
}

#intro {
    text-align: center
}
#container {
  display: flexbox;
  flex-grow: 1;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: auto;
}


#submit {
    background-image: url("/public/images/send.png");
    color: white;
    /*padding: 12px 20px;*/
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    color: transparent; /* Hide button text */
    padding: 20px 70px; 
    background-color: #06971b;
  }

#name, #email, #message {
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.extra {
  flex-grow: 1;
}



 